import React from "react"

import humans1 from "../../assets/images/ab-img.png"

function AboutMe() {
  return (
    <div className="overflow-hidden">
      <div
        className={`container px-4 mx-auto border-t`}
        style={{ paddingTop: "7rem" }}
      >
        <div className="text-center md:text-left flex flex-col md:flex-row">
          <div className=" flex-1">
            <img
              style={{
                maxWidth: "100%",
                height: "auto",
              }}
              src={humans1}
              alt=""
            />
          </div>
          <div className="flex-1 pl-0 md:pl-16">
            <h2 className="font-bold text-4xl">About Me</h2>
            <p className="font-medium pt-6">
              Hello, I’m a web-developer from Bangladesh. I have rich experience
              in web site design & building and customization. Also I am good at
            </p>
            <ul className="flex flex-wrap font-medium pt-8 gap-3 mb-4">
              <li>
                <span className="pb-1 px-2 m-0 border rounded border-teal-500">
                  Javascript
                </span>
              </li>
              <li>
                <span className="pb-1 px-2 m-0 border rounded border-teal-500">
                  React
                </span>
              </li>
              <li>
                <span className="pb-1 px-2 m-0 border rounded border-teal-500">
                  NodeJs
                </span>
              </li>
              <li>
                <span className="pb-1 px-2 m-0 border rounded border-teal-500">
                  Redux
                </span>
              </li>
              <li>
                <span className="pb-1 px-2 m-0 border rounded border-teal-500">
                  Nextjs
                </span>
              </li>
              <li>
                <span className="pb-1 px-2 m-0 border rounded border-teal-500">
                  Gatsby
                </span>
              </li>
              <li>
                <span className="pb-1 px-2 m-0 border rounded border-teal-500">
                  Vue
                </span>
              </li>
            </ul>
            <div className="text-center mt-10">
              <a
                className="px-4 py-2 bg-teal-600 rounded-md text-white"
                href="https://drive.google.com/u/0/uc?id=1W7CHuuVFUQn_Ku8ZWAXyHIRjTkNKp20V&export=download"
                target="_blank"
                rel="noopener noreferrer"
              >
                Download Resume
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AboutMe
