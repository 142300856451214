import React from "react";
import Banner from "./Banner";
import AboutMe from "./AboutMe";
import RecentWorks from "./RecentWorks";
import Contact from "./Contact";
function Index() {
  return (
    <>
      <Banner />
      <AboutMe />
      <RecentWorks/>
      <Contact />
    </>
  );
}

export default Index;
