import React from "react"
import ContactForm from "../global/ContactForm"
import contactImg from "../../assets/images/humaans.png"

function Contact() {
  return (
    <div className="container mx-auto py-24">
      <h1 className="text-center font-bold text-5xl pb-16">Contact Me</h1>
      <div className="flex flex-col md:flex-row">
        <div className="flex-1 mx-1 p-5 justify-center">
          <img src={contactImg} alt="" />
        </div>
        <div className="flex-1 mx-1">
          <ContactForm />
        </div>
      </div>
    </div>
  )
}

export default Contact
