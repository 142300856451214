import * as React from "react"
import myImage from "../../assets/images/me.jpg"
import SocialRoundButtons from "../global/SocialRoundButtons"
import { MdEmail } from "react-icons/md"
import { FaPhoneAlt } from "react-icons/fa"
import { IoLocationSharp } from "react-icons/io5"
function Banner() {
  return (
    <div className="px-4 container mx-auto" style={{ paddingBottom: "7rem" }}>
      <div
        style={{ paddingTop: "7rem" }}
        className="text-center md:text-left flex flex-col-reverse md:flex-row"
      >
        <div className="flex-1 pt-5 mt-5 ">
          <div className=" my-2">
            <span className="px-5 py-2 bg-teal-400 rounded-full rounded-bl-none text-gray-100 font-medium">
              Hello I'm
            </span>
          </div>
          <h1 className="text-5xl lg:text-5xl font-bold">Faisal Ahmed</h1>
          <p className="text-3xl font-semibold mb-3 ">Software Engineer</p>
          <ul className="font-semibold pl-1">
            <li className=" gap-1 pt-3">
              <div className="inline-block">
                <div className="flex items-center gap-2">
                  <MdEmail />
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="mailto:faisalantu@gmail.com"
                  >
                    faisalantu@gmail.com
                  </a>
                </div>
              </div>
            </li>
            <li className=" gap-1 pt-3">
              <div className="inline-block">
                <div className="flex items-center gap-2">
                  <FaPhoneAlt />
                  <a href="tek:+880 1776-197074">+880 1776-197074</a>
                </div>
              </div>
            </li>
            <li className=" gap-1 pt-3">
              <div className="inline-block">
                <div className="flex items-center gap-2">
                  <IoLocationSharp />
                  <span>Rajshahi, Bangladesh</span>
                </div>
              </div>
            </li>
          </ul>
          <div className="mt-5 ml-0 md:ml-2">
            <SocialRoundButtons />
          </div>
        </div>
        <div className="flex-1">
          <div
            className="mx-auto rounded-full"
            style={{
              maxHeight: "400px",
              maxWidth: "400px",
              border: "20px solid rgba(0, 0, 0, 0.07)",
            }}
          >
            <img
              className="mx-auto text-center rounded-full"
              style={{
                border: "20px solid rgba(0, 0, 0, 0.17)",
                maxWidth: "100%",
                height: "auto",
              }}
              src="https://avatars.githubusercontent.com/u/26870205?v=4"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Banner
