import React, { useState } from "react"
import PostCard from "../global/postCard"
import Masonry from "react-masonry-css"
import { Link, graphql, useStaticQuery } from "gatsby"
function RecentWorks() {
  const data = useStaticQuery(graphql`
    query RecentWorks {
      allMarkdownRemark {
        nodes {
          frontmatter {
            imageAlt
            category
            title
            description
            date
            type
            featuredImage {
              childImageSharp {
                gatsbyImageData(
                  width: 400
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
          excerpt
          fields {
            slug
          }
        }
      }
    }
  `)

  const [categoryClass, setCategoryClass] = useState("all")
  const breakpointColumnsObj = {
    default: 3,
    1280: 3,
    1024: 3,
    768: 2,
    640: 1,
  }
  const changeActiveClass = activeClass => {
    setCategoryClass(activeClass)
  }

  return (
    <div className="mx-auto container mb-8" style={{ paddingTop: "10rem" }}>
      <h1 className="font-bold text-5xl text-center">Recent Works</h1>
      <ul className="flex flex-wrap justify-center font-medium text-1xl py-12">
        <li>
          <button
            className={`border-b-2 border-transparent cursor-pointer hover:border-teal-500 ml-6 focus:outline-none ${
              categoryClass === "all" ? "border-teal-600 " : ""
            }`}
            onClick={() => {
              changeActiveClass("all")
            }}
          >
            All Categories
          </button>
        </li>
        <li>
          <button
            className={`border-b-2 border-transparent cursor-pointer hover:border-teal-500 ml-6 focus:outline-none ${
              categoryClass === "web" ? "border-teal-600 " : ""
            }`}
            onClick={() => {
              changeActiveClass("web")
            }}
          >
            Web Development
          </button>
        </li>
        <li>
          <button
            className={`border-b-2 border-transparent cursor-pointer hover:border-teal-500 ml-6 focus:outline-none ${
              categoryClass === "ui-ux" ? "border-teal-600 " : ""
            }`}
            onClick={() => {
              changeActiveClass("ui-ux")
            }}
          >
            UI/UX
          </button>
        </li>
        <li>
          <button
            className={`border-b-2 border-transparent cursor-pointer hover:border-teal-500 ml-6 focus:outline-none ${
              categoryClass === "bot" ? "border-teal-600 " : ""
            }`}
            onClick={() => {
              changeActiveClass("bot")
            }}
          >
            Bot
          </button>
        </li>
        <li>
          <button
            className={`border-b-2 border-transparent cursor-pointer hover:border-teal-500 ml-6 focus:outline-none ${
              categoryClass === "other" ? "border-teal-600 " : ""
            }`}
            onClick={() => {
              changeActiveClass("other")
            }}
          >
            Other
          </button>
        </li>
      </ul>
      <Masonry
        breakpointCols={breakpointColumnsObj}
        className="my-masonry-grid gap-y-5"
        columnClassName="my-masonry-grid_column"
      >
        {data.allMarkdownRemark.nodes.map((post, i) => {
          if(post.frontmatter.type !== "project") return null
          if (
            categoryClass === post.frontmatter.category ||
            categoryClass === "all"
          ) {
            return (
              <div key={i}>
                <Link to={post.fields.slug}>
                  <PostCard post={post} />
                </Link>
              </div>
            )
          } else return null
        })}
      </Masonry>
    </div>
  )
}

export default RecentWorks
